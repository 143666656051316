import React, { useEffect } from "react";
import { Col, Row, Steps } from "antd";
import { useDispatch, useSelector } from "react-redux";

import lang from "../../../language/language";
import "../styles/style.css";
import StepLocation from "../components/StepLocation";
import { setCurrentStep } from "../slice";
import StepTime from "../components/StepTime";
import StepStaffService from "../components/StepStaffService";
import StepInfomation from "../components/StepInfomation";
import { useParams } from "react-router-dom";
import * as bookingThunk from "./../thunk";
import InfoSalon from "../components/InfoSalon";

const BookingPage = () => {
  const Step = Steps.Step;
  const currentStep = useSelector((state) => state.booking.currentStep);
  const dispatch = useDispatch();
  const params = useParams();
  const branch = useSelector((state) => state.booking.branch);

  useEffect(() => {
    dispatch(bookingThunk.getBranchList({ company_code: params?.companyCode }));
    dispatch(bookingThunk.getCompany({ company_code: params?.companyCode }));
    dispatch(
      bookingThunk.getGeneralSettingPublic({
        company_code: params?.companyCode,
      })
    );
    if (branch?.branch_id) {
      dispatch(
        bookingThunk.getAppointmentSetting({
          company_code: params?.companyCode,
          branch_id: branch?.branch_id,
        })
      );
    }
  }, [params?.companyCode, branch]);

  const stepContent = [
    {
      title: lang.choose_location,
      content: <StepLocation />,
    },
    {
      title: lang.choose_staff_service,
      content: <StepStaffService />,
    },
    {
      title: lang.choose_time,
      content: <StepTime />,
    },
    {
      title: lang.customer_info,
      content: <StepInfomation />,
    },
  ];

  const onChange = (value) => {
    dispatch(setCurrentStep(value));
  };
  return (
    <Row gutter={24}>
      <Col md={24} xs={24}>
        <InfoSalon />
      </Col>
      <Col md={24} xs={24}>
        <div>
          <Steps
            className="steps-process pb-30"
            current={currentStep}
            onChange={onChange}
          >
            <Step
              title={lang.choose_location}
              status={
                currentStep > 0
                  ? "finish"
                  : currentStep === 0
                  ? "process"
                  : "wait"
              }
              disabled={currentStep < 0}
            />
            <Step
              title={lang.choose_staff_service}
              status={
                currentStep > 1
                  ? "finish"
                  : currentStep === 1
                  ? "process"
                  : "wait"
              }
              disabled={currentStep < 1}
            />
            <Step
              title={lang.choose_time}
              status={
                currentStep > 2
                  ? "finish"
                  : currentStep === 2
                  ? "process"
                  : "wait"
              }
              disabled={currentStep < 2}
            />
            <Step
              title={lang.customer_info}
              status={
                currentStep > 3
                  ? "finish"
                  : currentStep === 3
                  ? "process"
                  : "wait"
              }
              disabled={currentStep < 3}
            />
          </Steps>
          <div className="steps-content text-left">
            {stepContent[currentStep].content}
          </div>
        </div>
      </Col>
    </Row>
  );
};
export default BookingPage;
