import React from "react";
import { Col, Row } from "antd";
import ShowStaffList from "./ShowStaffList";
import ShowServiceList from "./ShowServicesList";
import { useSelector } from "react-redux";

const ChooseByService = () => {
  const itemList = useSelector((state) => state?.booking?.itemList);

  return (
    <Row gutter={24}>
      <Col md={24} xs={24}>
        <ShowServiceList />
      </Col>
      {itemList?.length > 0 && (
        <Col md={24} xs={24}>
          <ShowStaffList />
        </Col>
      )}
    </Row>
  );
};

export default ChooseByService;
