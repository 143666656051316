import React, { useEffect } from "react";
import { Avatar, Card, Input, List, message } from "antd";
import { CheckCircleFilled } from "@ant-design/icons";

import lang from "../../../language/language";
import noImage from "../../../assets/images/default-user.png";
import { useDispatch, useSelector } from "react-redux";
import {
  setItemList,
  setQueryServiceList,
  setQueryStaffList,
  setStaffChoosed,
  setStaffList,
} from "../slice";
import { getStaffAutoTurnPublic } from "../service";
import moment from "moment";
import * as bookingThunk from "../thunk";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

const { Search } = Input;

const ShowStaffList = () => {
  const staffList = useSelector((state) => state.booking.staffList);
  const queryStaffList = useSelector((state) => state.booking.queryStaffList);
  const staffChoosed = useSelector((state) => state.booking.staffChoosed);
  const queryServiceList = useSelector(
    (state) => state.booking.queryServiceList
  );
  const itemList = useSelector((state) => state.booking.itemList);
  const params = useParams();
  const branch = useSelector((state) => state.booking.branch);
  const currentTabs = useSelector((state) => state.booking.currentTab);

  const dispatch = useDispatch();

  useEffect(() => {
    if (staffList.length > 0 && !staffChoosed.staff_id) {
      getStaffAuto();
    }
  }, [staffChoosed.staff_id, staffList]);

  useEffect(() => {
    if (branch.branch_id && params.companyCode) {
      if (currentTabs === "1") {
        fetchDataStaffList({
          ...queryStaffList,
          listBranchId: [branch.branch_id],
          company_code: params.companyCode,
          listServiceId: [],
          date: moment().format("YYYY-MM-DD"),
        });
      }
      if (currentTabs === "2") {
        if (queryStaffList?.listServiceId?.length > 0) {
          fetchDataStaffList({
            ...queryStaffList,
            listBranchId: [branch.branch_id],
            company_code: params.companyCode,
            listServiceId: queryStaffList.listServiceId,
            date: moment().format("YYYY-MM-DD"),
          });
        } else {
          dispatch(setStaffList([]));
        }
      }
    }
  }, [queryStaffList, currentTabs]);

  const fetchDataStaffList = (data = {}) => {
    dispatch(bookingThunk.staffList(data));
  };

  const getStaffAuto = () => {
    if (!staffChoosed.staff_id) {
      dispatch(setStaffChoosed({}));
      getStaffAutoTurnPublic({
        date: moment().format("YYYY-MM-DD"),
        company_code: params?.companyCode,
      }).then((res) => {
        if (res.data) {
          const findStaff = staffList.filter(
            (x) => x.staff_id === res.data.staff_id
          );
          if (findStaff.length > 0) {
            if (currentTabs === "1") {
              dispatch(
                setQueryServiceList({
                  ...queryServiceList,
                  staff_id: res.data.staff_id,
                  company_code: params.companyCode,
                })
              );
            }
            dispatch(setStaffChoosed(res.data));
            const newItemList = itemList.map((item) => {
              return { ...item, ...res.data };
            });
            dispatch(setItemList(newItemList));
          }
        }
      });
    }
  };

  const onClickStaff = (data) => {
    // }
    // if (data.is_busy === 1) {
    //     message.error(lang.staff_is_busy)
    // }
    if (currentTabs === "1") {
      dispatch(setItemList([]));
      dispatch(
        setQueryServiceList({
          ...queryServiceList,
          staff_id: data.staff_id,
          company_code: params.companyCode,
        })
      );
    }
    dispatch(setStaffChoosed(data));
  };

  const onSearch = (value) => {
    dispatch(
      setQueryStaffList({
        ...queryStaffList,
        search: value,
        company_code: params.companyCode,
      })
    );
  };

  return (
    <Card title={lang.staff_choosed} className="mt-15">
      <Search
        size="middle"
        className="i-search mb-15"
        placeholder={lang.find_staff}
        enterButton
        onSearch={onSearch}
      />
      <List
        style={{
          height: 300,
          overflow: "auto",
        }}
        size="large"
        dataSource={staffList}
        renderItem={(item) => (
          <List.Item
            className="cursor-pointer"
            onClick={() => onClickStaff(item)}
          >
            {parseInt(item.staff_id) === parseInt(staffChoosed.staff_id) && (
              <div className="choose-staff">
                <div>
                  <CheckCircleFilled className="btn-choose" color="primary" />
                </div>
              </div>
            )}
            <List.Item.Meta
              title={<b>{item.staff_fullname}</b>}
              description={item.staff_code}
              avatar={<Avatar size={50} src={item.staff_avatar || noImage} />}
            />
          </List.Item>
        )}
      />
    </Card>
  );
};

export default ShowStaffList;
