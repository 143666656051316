// service
const rootWebApp = "web-app";
export const STAFF = `${rootWebApp}/staff`;
export const BRANCH_LIST = `${rootWebApp}/get-branch`;
export const AUTO_TURN = `${rootWebApp}/staff-auto-turn-public`;
export const SERVICE_TREE = `${rootWebApp}/get-service-tree`;
export const GENERAL_SETTING = `${rootWebApp}/general-setting`;
export const APPOINTMENT_SETTING = `${rootWebApp}/appointment-setting`;
export const APPOINTMENT_HOUR_LIST = `${rootWebApp}/appointment-hour-list`;
export const APPOINTMENT_CREATE = `${rootWebApp}/appointment`;
export const OPEN_HOUR_SETTING = `${rootWebApp}/open-hour-setting`;
export const DAY_OFF_SETTING = `${rootWebApp}/day-off-setting`;
export const COMPANY = `${rootWebApp}/get-company-info`;
