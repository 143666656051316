import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Input,
  message,
  Checkbox,
  Modal,
  Alert,
} from "antd";
import lang from "../../../language/language";
import TextArea from "antd/es/input/TextArea";
import ShowInfo from "./ShowInfo";
import { useSelector } from "react-redux";
import { CREATE_FROM } from "../../../constants/common";
import moment from "moment";
import { RandomCode } from "../../Common/components/RandomCode";
import { appointmentCreate } from "../service";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import dayjs from "dayjs";
import stringReplace from "react-string-replace";

const StepInfomation = () => {
  const [form] = Form.useForm();
  const branch = useSelector((state) => state.booking.branch);
  const booking = useSelector((state) => state.booking);
  const staffChoosed = useSelector((state) => state.booking.staffChoosed);
  const [isCancellationModalVisible, setIsCancellationModalVisible] =
    useState(false);
  const [isPrivacyModalVisible, setIsPrivacyModalVisible] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const company = useSelector((state) => state?.booking?.company);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    setErrorMessage("");
  };

  const showCancellationModal = () => {
    setIsCancellationModalVisible(true);
  };

  const showPrivacyModal = () => {
    setIsPrivacyModalVisible(true);
  };

  const handleCancel = () => {
    setIsCancellationModalVisible(false);
    setIsPrivacyModalVisible(false);
  };

  const params = useParams();

  const countEndHour = (data = []) => {
    if (data && data.length > 0) {
      let countDuration = 0;
      if (data.length > 0) {
        data.map((x) => {
          countDuration += x?.service_duration;
        });
        return moment(booking?.hourSelected?.value, "hh:mm")
          .add(countDuration, "minutes")
          .format("HH:mm")
          .toString();
      } else {
        return "";
      }
    } else {
      return "";
    }
  };

  const onSubmit = (data) => {
    event.preventDefault();

    if (!isChecked) {
      setErrorMessage(lang.required_check_terms_and_conditions);
      return;
    }
    const dataSubmit = {
      appointment_date: dayjs(booking?.dateSelected).format("YYYY-MM-DD"),
      appointment_hour: booking?.hourSelected?.value,
      appointment_status: 1,
      appointment_note: data?.appointment_note || "",
      customer_fullname: data?.customer_fullname,
      customer_phone_number: data?.customer_phone_number,
      customer_email: data?.customer_email,
      create_user: -1,
      branch_id: branch?.branch_id,
      create_by: CREATE_FROM.WEBSITE,
      service_staff_list: booking?.itemList,
      appointment_end_hour: countEndHour(booking?.itemList),
      customer_code: RandomCode(),
      company_code: params?.companyCode,
      appointment_quantity: 1,
      staff_id_block: staffChoosed?.staff_id,
    };
    appointmentCreate(dataSubmit)
      .then((res) => {
        if (res.data) {
          message.success(lang.booking_success);
        }
      })
      .finally((x) => {
        setTimeout(() => {
          window.location.reload();
        }, 500);
      });
  };
  const onSave = () => {
    form.submit();
  };

  const replacedText = stringReplace(
    stringReplace(
      lang.policy_notice_3?.replace("[salon_name]", company?.company_name),
      "cancellation policy",
      (match, i) => (
        <span
          key={i}
          onClick={showCancellationModal}
          style={{
            color: "blue",
            textDecoration: "underline",
            cursor: "pointer",
          }}
        >
          {match}
        </span>
      )
    ),
    "terms and conditions/privacy policy",
    (match, i) => (
      <span
        key={i}
        onClick={showPrivacyModal}
        style={{
          color: "blue",
          textDecoration: "underline",
          cursor: "pointer",
        }}
      >
        {match}
      </span>
    )
  );

  const replacedpolicyNotice2 = stringReplace(
    lang.policy_notice_2,
    "*",
    (match, i) => (
      <span key={i} style={{ color: "red", fontWeight: "bold" }}>
        *
      </span>
    )
  );

  return (
    <Row>
      <ShowInfo />
      <Col md={24} xs={24}>
        <Card>
          <Form
            name="basic"
            onFinish={onSubmit}
            // onFinishFailed={onFinishFailed}
            layout="vertical"
            autoComplete="off"
            form={form}
          >
            <Row gutter={12}>
              <Col md={24} xs={24}>
                {lang.policy_notice_1}
              </Col>
              <Col md={8} xs={24}>
                <Form.Item
                  label={lang.customer_fullname}
                  name="customer_fullname"
                  rules={[
                    { required: true, message: lang.name_is_required },
                    {
                      pattern:
                        /^[A-Za-z 0-9_ÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷýỹ']*$/,
                      message: lang.not_allow_special_characters,
                    },
                  ]}
                >
                  <Input style={{ textTransform: "uppercase" }} />
                </Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item
                  label={lang.customer_phone_number}
                  name="customer_phone_number"
                  rules={[
                    { required: true, message: lang.phone_is_required },
                    { pattern: /^[0-9]*$/, message: lang.phone_incorrect },
                    { min: 10, message: lang.phone_min_max },
                    { max: 11, message: lang.phone_min_max },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col md={8} xs={24}>
                <Form.Item
                  label="Email"
                  name="customer_email"
                  rules={[{ type: "email", message: lang.email_not_format }]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col md={24} xs={24}>
                {replacedpolicyNotice2}
              </Col>
              <Col md={24} xs={24}>
                <Form.Item label={lang.customer_note} name="appointment_note">
                  <TextArea />
                </Form.Item>
              </Col>
              <Col md={24} xs={24}>
                <Checkbox checked={isChecked} onChange={handleCheckboxChange}>
                  <p>{replacedText}</p>

                  {/* Modal for Cancellation Policy */}
                  <Modal
                    title="Cancellation Policy"
                    open={isCancellationModalVisible}
                    onCancel={handleCancel}
                    footer={null}
                  >
                    <p>Here are the details of the cancellation policy...</p>
                  </Modal>

                  {/* Modal for Privacy Policy */}
                  <Modal
                    title="Terms and Conditions/Privacy Policy"
                    open={isPrivacyModalVisible}
                    onCancel={handleCancel}
                    footer={null}
                  >
                    <p>
                      Here are the details of the terms and conditions/privacy
                      policy...
                    </p>
                  </Modal>
                </Checkbox>
                {errorMessage && (
                  <Form.Item>
                    <Alert message={errorMessage} type="error" showIcon />
                  </Form.Item>
                )}
              </Col>
            </Row>
          </Form>
        </Card>
      </Col>
      <Col md={24} xs={24}>
        <div className="text-center mt-30">
          <Button size="large" type="primary" onClick={onSave}>
            {lang.submit}
          </Button>
          <p>{lang.policy_notice_4}</p>
        </div>
      </Col>
    </Row>
  );
};
export default StepInfomation;
