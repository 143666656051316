import React from "react";
import { useSelector } from "react-redux";
import { Image } from "antd";
import logo from "../../../assets/images/hextra-logo.png";
import { formatPhone } from "../../Common/components/FormatData";
const InfoSalon = () => {
  const branch = useSelector((state) => state?.booking?.branch);
  const company = useSelector((state) => state?.booking?.company);

  return (
    <div className="pb-30 text-center">
      <div className="text-center">
        <Image src={logo} width={200} />
      </div>
      {branch?.branch_id && (
        <>
          <h2>{company?.company_name}</h2>
          <h5>{formatPhone(company?.company_phone_number)}</h5>
          <h5>{company?.company_address} </h5>
        </>
      )}
    </div>
  );
};
export default InfoSalon;
