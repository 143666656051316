import CallApi from "../../utils/CallAPI";
import {
  STAFF,
  AUTO_TURN,
  BRANCH_LIST,
  SERVICE_TREE,
  GENERAL_SETTING,
  APPOINTMENT_SETTING,
  APPOINTMENT_HOUR_LIST,
  APPOINTMENT_CREATE,
  OPEN_HOUR_SETTING,
  DAY_OFF_SETTING,
  COMPANY,
} from "./constants/endpoint";

export const getStaff = (data) => {
  return CallApi(STAFF, "GET", data);
};

export const getStaffAutoTurnPublic = (data) => {
  return CallApi(AUTO_TURN, "GET", data);
};

export const getBranchList = (data) => {
  return CallApi(BRANCH_LIST, "GET", data);
};

export const getServiceTree = (data) => {
  return CallApi(SERVICE_TREE, "GET", data);
};

export const getGeneralSetting = (data) => {
  return CallApi(GENERAL_SETTING, "GET", data);
};

export const getAppointmentSetting = (data) => {
  return CallApi(APPOINTMENT_SETTING, "GET", data);
};

export const getAppointmentHourList = (data) => {
  return CallApi(APPOINTMENT_HOUR_LIST, "GET", data);
};

export const appointmentCreate = (data) => {
  return CallApi(APPOINTMENT_CREATE, "POST", data);
};

export const openHourSetting = (data) => {
  return CallApi(OPEN_HOUR_SETTING, "GET", data);
};

export const dayOffSetting = (data) => {
  return CallApi(DAY_OFF_SETTING, "GET", data);
};

export const company = (data) => {
  return CallApi(COMPANY, "GET", data);
};
