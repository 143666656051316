import React from "react";
import { Col, Row } from "antd";
import ShowStaffList from "./ShowStaffList";
import ShowServiceList from "./ShowServicesList";
import { useSelector } from "react-redux";

const ChooseByStaff = () => {
  const staffChoosed = useSelector((state) => state.booking.staffChoosed);

  return (
    <Row gutter={24}>
      <Col md={24} xs={24}>
        <ShowStaffList />
      </Col>
      {staffChoosed?.staff_id && (
        <Col md={24} xs={24}>
          <ShowServiceList />
        </Col>
      )}
    </Row>
  );
};

export default ChooseByStaff;
