import Main from "./Main";
import { BrowserRouter as Router } from "react-router-dom";
import React from "react";
import { ToastContainer } from "react-toastify";
import Spinner from "./themes/Antd/Spinner";
import { useSelector } from "react-redux";
import { ConfigProvider } from "antd";
const App = (props) => {
  const loading = useSelector((state) => state.common.loading);
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#894ae2", // Màu chính cho tất cả các component
          controlOutline: "none", // Loại bỏ outline khi hover
          controlOutlineWidth: 0, // Đặt width của outline thành 0
        },
      }}
    >
      <Router>
        <div className="app-container">
          <div>
            <Main />
            <Spinner loading={loading || false} />
          </div>
          <ToastContainer
            position="top-right"
            autoClose={3500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      </Router>
    </ConfigProvider>
  );
};

export default App;
